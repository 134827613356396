module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177400045-1","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e9e9e9","display":"standalone","icon":"./static/favicon.png","lang":"en","legacy":false,"name":"Jeremiah Stanley - Portfolio","short_name":"Jeremiah Stanley","start_url":"/","theme_color":"#008ae5","cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"cc3632ab769d5cccd90f9f35705835aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
